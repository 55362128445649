import React, { useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Link from 'gatsby-link';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';
import Button from '../../components/ui/Button';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Icon from '../../components/Icon';
import CopyText from '../../components/CopyText';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Card } from '../../components/Posts/styles';
import { motion } from 'framer-motion';
import Img, { FluidObject } from 'gatsby-image';
import * as StyledParent from '../../components/Posts/styles';
import { useAppDispatch, useAppSelector } from '../../infrastructure/store';
import { selectFilter, setNewsFilter } from '../../infrastructure/news';
import getCompareString from '../../utils/getCompareString';
import * as styles from './styles.module.scss';
import Headline from '../../components/Headline';

function getSuggestionTitle(name: string, term: string) {
  const termIndex = getCompareString(name).indexOf(term);
  const termLength = term.length;

  if (termIndex === -1) {
    return name;
  }

  return (
    <>
      {name.substring(0, termIndex)}
      <span className={styles.highlight}>{name.substring(termIndex, termIndex + termLength)}</span>
      {name.substring(termIndex + termLength)}
    </>
  );
}

export const PostComponent = styled.div`
  ${tw`w-full p-0 mb-12`};
`;

interface Post {
  fields: {
    slug: string;
  };
  id: string;
  html: string;
  frontmatter: {
    title: string;
    contenttitle: string;
    description: string;
    date: string;
    tags: string[];
    cover: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
}

interface Props {
  data: {
    markdownRemark: Post;
    allMarkdownRemark: {
      edges: {
        node: Post;
      }[];
    };
  };
  pageContext: {
    slug: string;
    next: Post;
    previous: Post;
    posts: Post[];
  };
}

export const Tags = styled.div`
  ${tw`p-16 pt-2 mt-auto`}
`;

export const Tag = styled.span`
  ${tw`text-sm text-indigo-900 border rounded-lg px-4 py-2 mr-8 inline-flex mt-4 flex-nowrap`}
  border: #cdb08e 1px solid; 
`;

const getFilteredPosts = (posts: Post[], newsFilter: string | null) => {
  if (newsFilter && newsFilter.length > 2) {
    const compareAgainst = getCompareString(newsFilter);

    const newFilteredPosts = posts.filter(post => {
      const { title, description, tags, date } = post.frontmatter;
      const { html } = post;

      return (
        getCompareString(title).includes(compareAgainst) ||
        getCompareString(description).includes(compareAgainst) ||
        tags.find(tag => getCompareString(tag).includes(compareAgainst)) ||
        getCompareString(date).includes(compareAgainst) ||
        getCompareString(html).includes(compareAgainst)
      );
    });

    return newFilteredPosts;
  } else {
    return [];
  }
};

const NewsPost: React.FC<Props> = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const allPosts = useMemo(
    () =>
      data.allMarkdownRemark.edges.reduce(
        (a, { node }) => ({
          ...a,
          [node.id]: {
            cover: node.frontmatter.cover,
          },
        }),
        {}
      ),
    []
  );

  const { t } = useI18next();
  const dispatch = useAppDispatch();
  const { previous, next, posts } = pageContext;
  const newsFilter = useAppSelector(selectFilter);
  const [filteredPosts] = useState(getFilteredPosts(posts, newsFilter));
  const compareAgainst = getCompareString(newsFilter || '');

  const postsToRender = (
    filteredPosts && filteredPosts.length > 0 ? filteredPosts : previous || next ? [previous || next] : []
  ).filter(p => p.id !== post.id);

  return (
    <Layout id="news">
      <SEO title={post.frontmatter.contenttitle} />
      <Container section>
        <Link to={`/news`}>
          <Button className="flex items-center px-10 py-4 mb-24">
            <Icon name="Back" size="s" additionalClass="mr-4" />
            <CopyText variant="copy-1">{t('backToNews')}</CopyText>
          </Button>
        </Link>
        <TitleSection
          large
          title={post.frontmatter.date}
          subtitle={post.frontmatter.contenttitle}
          noSeparator
        />
        <Headline variant="headline-5" additionalClass="my-8 text-center mb-30">
          {post.frontmatter.description}
        </Headline>
        <div className="flex w-full items-start h-full overflow-auto">
          <div className={`flex flex-col h-full pr-24 ${styles.headings}`} style={{ flex: 6 }}>
            <FormatHtml content={post.html} />
          </div>
          <div className="no-mobile flex-col h-full w-full" style={{ flex: 2 }}>
            <Card style={{ marginBottom: '16px' }}>
              <Tags>
                <CopyText variant="copy-2" additionalClass="my-8 text-center">
                  {t('keywords')}
                </CopyText>
                {post.frontmatter.tags.map(item => (
                  <Tag key={item}>{item}</Tag>
                ))}
              </Tags>
            </Card>
            {newsFilter && newsFilter.length > 2 && (
              <div className="flex flex-col w-full">
                <CopyText variant="copy-2" additionalClass="my-16">
                  {t('otherArticlesMatching')}&nbsp;"{newsFilter}"
                </CopyText>
                <Button
                  className="flex items-center px-10 py-4 mb-24"
                  onClick={() => {
                    dispatch(setNewsFilter(null));
                  }}
                >
                  {t('clearFilter')}
                </Button>
              </div>
            )}
            {postsToRender.length > 0 && (
              <StyledParent.Posts>
                {postsToRender.map(item => {
                  const {
                    id,
                    fields: { slug },
                    frontmatter: { title, description, tags, date },
                  } = item;

                  const cover =
                    (allPosts &&
                      allPosts[id as keyof typeof allPosts] &&
                      // @ts-ignore
                      allPosts[id as keyof typeof allPosts].cover) ||
                    null;

                  return (
                    <PostComponent key={id}>
                      <Link to={slug}>
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
                          <StyledParent.Card>
                            {cover && (
                              <StyledParent.Image>
                                {/* @ts-ignore */}
                                <Img fluid={cover?.childImageSharp?.fluid} alt="News Post Image" />
                              </StyledParent.Image>
                            )}
                            <StyledParent.Content>
                              <StyledParent.Date>
                                {compareAgainst ? getSuggestionTitle(date, compareAgainst) : date}
                              </StyledParent.Date>
                              <StyledParent.Title>
                                {compareAgainst ? getSuggestionTitle(title, compareAgainst) : title}
                              </StyledParent.Title>
                              <StyledParent.Description>
                                {compareAgainst ? getSuggestionTitle(description, compareAgainst) : description}
                              </StyledParent.Description>
                            </StyledParent.Content>
                            <StyledParent.Tags>
                              {tags.map(item => (
                                <StyledParent.Tag key={item}>
                                  {compareAgainst ? getSuggestionTitle(item, compareAgainst) : item}
                                </StyledParent.Tag>
                              ))}
                            </StyledParent.Tags>
                          </StyledParent.Card>
                        </motion.div>
                      </Link>
                    </PostComponent>
                  );
                })}
              </StyledParent.Posts>
            )}
          </div>
        </div>
        <Styled.Links>
          <span>
            {previous && (
              <Link to={previous.fields.slug} rel="previous">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </span>
          <span>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </span>
        </Styled.Links>
      </Container>
    </Layout>
  );
};

export default NewsPost;

export const query = graphql`
  query NewsPostBySlug($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      id
      frontmatter {
        tags
        title
        contenttitle
        description
        date(formatString: "MMM DD, YYYY")
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "news" }, published: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
